exports.components = {
  "component---dev-website-src-pages-404-tsx": () => import("./../../../../dev-website/src/pages/404.tsx" /* webpackChunkName: "component---dev-website-src-pages-404-tsx" */),
  "component---dev-website-src-templates-article-index-tsx": () => import("./../../../../dev-website/src/templates/Article/index.tsx" /* webpackChunkName: "component---dev-website-src-templates-article-index-tsx" */),
  "component---dev-website-src-templates-article-listing-index-tsx": () => import("./../../../../dev-website/src/templates/ArticleListing/index.tsx" /* webpackChunkName: "component---dev-website-src-templates-article-listing-index-tsx" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */)
}

